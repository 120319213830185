import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  visit(event) {
    // Don't trigger if user is selecting text
    if (window.getSelection().toString()) {
      return
    }

    // Don't trigger if the click was on a link or button
    if (event.target.closest('a, button')) {
      return
    }

    window.location.href = this.urlValue
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
